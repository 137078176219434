import axios from "axios";
import React, { useEffect, useState } from "react";
import Box from "../components/Box";
import BoxLeft from "../components/BoxLeft";
import BoxRight from "../components/BoxRight";
import Logo from "../components/Logo";
import SortableTable from "../components/SortableTable";
import Title from "../components/Title";
import { API_URL } from "../constants/config";
import { useUser } from "../contexts/UserContext";
import DashboardLayout from "../layouts/Dashboard";
import { errorAlert } from "../utils/toast";

const Dashboard = () => {
  const { updateUser } = useUser();
  const { user } = useUser();

  const [vinRecord, setVinRecord] = useState({});
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    vin: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    setTableData([]);
    setLoading(true);

    let token = localStorage.getItem("token");
    axios
      .get(`${API_URL}/get-reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response);

        setTableData(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheck = () => {
    setLoading(true);
    setVinRecord({});

    let token = localStorage.getItem("token");

    axios
      .post(`${API_URL}/dashboard-vin-search`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        console.log(response);
        setVinRecord(response.data.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
        errorAlert(error.response.data?.message);
      });
  };

  function handleGetReport(e, vin, type) {
    e.preventDefault();

    if (+user.balance === 0 || +user.balance < 16) {
      errorAlert("Insufficient Balance");
      return;
    }

    setLoading(true);

    let token = localStorage.getItem("token");

    axios
      .post(
        `${API_URL}/dashboard-get-report`,
        {
          vin: vin,
          type: type,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log(response, "dashboard-get-report");
        setLoading(false);
        window.open("https://checkcar.site/dashboard", "_blank");
        fecthUser();
        fetchData();
      })
      .catch(function (error) {
        console.log(error);
        errorAlert(error.response.data?.message);
        setLoading(false);
      });
  }
  const fecthUser = async () => {
    let token = localStorage.getItem("token");
    const response = await axios.post(`${API_URL}/get/user`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response?.data?.data) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
      updateUser(response.data.data);
    }
  };
  const fields = [
    {
      id: "vin",
      label: "Vin",
    },
    {
      id: "created_at",
      label: "Date",
    },
    {
      id: "price",
      label: "Price, $",
    },
    {
      id: "type",
      label: "Type",
    },
    {
      id: "view",
      label: "View Report",
    },
    {
      id: "pdf",
      label: "PDF",
    },
  ];

  const HandleResult = (props) => {
    return (
      <>
        <div
          className={`${
            props.data.carfax.records ? "bg-cf--primary" : "bg-red-600"
          } text-white font-bold text-xl mb-2 p-3 text-center rounded shadow`}
        >
          <p className="mb-1">{props.data.carfax.vin}</p>
          <p>{props.data.carfax.vehicle ?? "null null"}</p>
        </div>
        <div className="text-sm">
          <div className="flex items-center justify-between gap-2 mb-2">
            {props.data.carfax.vehicle ? (
              <>
                CarFax
                <button
                  onClick={(e) =>
                    handleGetReport(e, props.data.carfax.vin, "carfax")
                  }
                  className="px-2 py-1 font-bold text-white bg-cf--primary rounded hover:bg-cf--secondary transition-all"
                >
                  Get report
                </button>
              </>
            ) : (
              <p className="text-red-600">CarFax Report is not available</p>
            )}
          </div>
          <div className="flex items-center justify-between gap-2">
            {props.data.carfax.vehicle ? (
              <>
                AutoCheck
                <button
                  onClick={(e) =>
                    handleGetReport(e, props.data.carfax.vin, "autocheck")
                  }
                  className="px-2 py-1 font-bold text-white bg-cf--primary rounded hover:bg-cf--secondary transition-all"
                >
                  Get report
                </button>
              </>
            ) : (
              <p className="text-red-600">AutoCheck Report is not available</p>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <DashboardLayout>
        {loading && (
          <>
            <div className="relative z-10">
              <div className="preloader-main w-full h-screen bg-white absolute opacity-90">
                <div className="preloader-div flex items-center justify-center flex-col h-full">
                  <Logo animate={true} />
                </div>
              </div>
            </div>
          </>
        )}
        <Title title={`Dashboard`} />
        <Box>
          <BoxLeft>
            <p className="mb-2">Get Report</p>
            <div className="flex items-center gap-2 mb-4">
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                placeholder="Vin code"
                name="vin"
                value={formData.vin}
                className="rounded-[5px] w-full border border-cf--light-grey bg-cf--off-white p-2"
              />
              <button
                disabled={loading}
                className="border pt-sans-bold tracking-wide text-white bg-cf--primary rounded-[5px] px-3 py-2 transition-all hover:bg-cf--secondary"
                onClick={handleCheck}
              >
                Check
              </button>
            </div>
            {vinRecord && vinRecord.carfax && <HandleResult data={vinRecord} />}
          </BoxLeft>
          <BoxRight>
            <SortableTable data={tableData} fields={fields} />
          </BoxRight>
        </Box>
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
