import PublicPageLayout from "../layouts/PublicPage";

const PrivacyPolicy = () => {
  return (
    <>
      <PublicPageLayout>
        <p className="pt-sans-bold text-3xl text-center mb-6">
          Polityka prywatności
        </p>
        <div className="p-6">
          <p className="text-black font-bold mt-2 text-lg">
            POLITYKA PRYWATNOŚCI SERWISU INTERNETOWEGO checkcar.site
          </p>
          <p className="text-black font-bold text-lg">Spis treści:</p>
          <p className="text-black text-base ml-5">1. POSTANOWIENIA OGÓLNE</p>
          <p className="text-black text-base ml-5">
            2. PODSTAWY PRZETWARZANIA DANYCH
          </p>
          <p className="text-black text-base ml-5">
            3. CEL, PODSTAWA, OKRES I ZAKRES PRZETWARZANIA DANYCH W SERWISIE
            INTERNETOWYM
          </p>
          <p className="text-black text-base ml-5">
            4. ODBIORCY DANYCH W SERWISIE INTERNETOWYM
          </p>
          <p className="text-black text-base ml-5">
            5. PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ
          </p>

          <p className="text-black font-bold mt-2 text-lg ">
            1. POSTANOWIENIA OGÓLNE
          </p>
          <p className="text-black text-base ml-5">
            1.1. Niniejsza polityka prywatności Serwisu Internetowego ma
            charakter informacyjny, co oznacza że nie jest ona źródłem
            obowiązków dla Usługobiorców Serwisu Internetowego. Polityka
            prywatności zawiera przede wszystkim zasady dotyczące przetwarzania
            danych osobowych przez Administratora w Serwisie Internetowym, w tym
            podstawy, cele i zakres przetwarzania danych osobowych oraz prawa
            osób.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.2. Administratorem danych osobowych zbieranych za pośrednictwem
            Serwisu Internetowego osobę fizyczna administrator, adres poczty
            elektronicznej: car_site@tutamail.com, adres e-mail jest dostępny na
            stronach Serwisu Internetowego.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.Dane osobowe w Serwisie Internetowym przetwarzane są przez
            Administratora zgodnie z obowiązującymi przepisami prawa, w
            szczególności zgodnie z rozporządzeniem Parlamentu Europejskiego i
            Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
            swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (ogólne rozporządzenie o ochronie danych) - zwane dalej „RODO” lub
            „Rozporządzenie RODO”. Oficjalny tekst Rozporządzenia RODO:
            http://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.4.Korzystanie ze Serwisu Internetowego, w tym zawieranie umów jest
            dobrowolne. Podobnie związane z tym podanie danych osobowych przez
            korzystającego ze Serwisu Internetowego Usługobiorcę lub Klienta
            jest dobrowolne, z zastrzeżeniem dwóch wyjątków: (1) zawieranie umów
            z Administratorem - niepodanie w przypadkach i w zakresie wskazanym
            na stronie Serwisu Internetowego oraz w Regulaminie Serwisu
            Internetowego i niniejszej polityce prywatności danych osobowych
            niezbędnych do zawarcia i wykonania umowy o świadczenie Usługi
            Elektronicznej z Administratorem skutkuje brakiem możliwości
            zawarcia tejże umowy. Podanie danych osobowych jest w takim wypadku
            wymogiem umownym i jeżeli osoba, które dane dotyczą chce zawrzeć
            daną umowę z Administratorem, to jest zobowiązana do podania
            wymaganych danych. Każdorazowo zakres danych wymaganych do zawarcia
            umowy wskazany jest uprzednio na stronie Serwisu Internetowego oraz
            w Regulaminie Serwisu Internetowego; (2) obowiązki ustawowe
            Administratora - podanie danych osobowych jest wymogiem ustawowym
            wynikającym z powszechnie obowiązujących przepisów prawa
            nakładających na Administratora obowiązek przetwarzania danych
            osobowych (np. przetwarzanie danych w celu prowadzenia ksiąg
            podatkowych lub rachunkowych) i brak ich podania uniemożliwi
            Administratorowi wykonanie tychże obowiązków.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.5.Administrator dokłada szczególnej staranności w celu ochrony
            interesów osób, których przetwarzane przez niego dane osobowe
            dotyczą, a w szczególności jest odpowiedzialny i zapewnia, że
            zbierane przez niego dane są: (1) przetwarzane zgodnie z prawem; (2)
            zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane
            dalszemu przetwarzaniu niezgodnemu z tymi celami; (3) merytorycznie
            poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane;
            (4) przechowywane w postaci umożliwiającej identyfikację osób,
            których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia
            celu przetwarzania oraz (5) przetwarzane w sposób zapewniający
            odpowiednie bezpieczeństwo danych osobowych, w tym ochronę przed
            niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz
            przypadkową utratą, zniszczeniem lub uszkodzeniem, za pomocą
            odpowiednich środków technicznych lub organizacyjnych.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.6.Uwzględniając charakter, zakres, kontekst i cele przetwarzania
            oraz ryzyko naruszenia praw lub wolności osób fizycznych o różnym
            prawdopodobieństwie i wadze zagrożenia, Administrator wdraża
            odpowiednie środki techniczne i organizacyjne, aby przetwarzanie
            odbywało się zgodnie z niniejszym rozporządzeniem i aby móc to
            wykazać. Środki te są w razie potrzeby poddawane przeglądom i
            uaktualniane. Administrator stosuje środki techniczne zapobiegające
            pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych
            osobowych przesyłanych drogą elektroniczną.
          </p>

          <p className="text-black font-bold mt-2 text-lg ">
            2. PODSTAWY PRZETWARZANIA DANYCH
          </p>
          <p className="text-black text-base ml-5">
            2.1.Administrator uprawniony jest do przetwarzania danych osobowych
            w przypadkach, gdy – i w takim zakresie, w jakim – spełniony jest co
            najmniej jeden z poniższych warunków: (1) osoba, której dane dotyczą
            wyraziła zgodę na przetwarzanie swoich danych osobowych w jednym lub
            większej liczbie określonych celów; (2) przetwarzanie jest niezbędne
            do wykonania umowy, której stroną jest osoba, której dane dotyczą,
            lub do podjęcia działań na żądanie osoby, której dane dotyczą, przed
            zawarciem umowy; (3) przetwarzanie jest niezbędne do wypełnienia
            obowiązku prawnego ciążącego na Administratorze; lub (4)
            przetwarzanie jest niezbędne do celów wynikających z prawnie
            uzasadnionych interesów realizowanych przez Administratora lub przez
            stronę trzecią, z wyjątkiem sytuacji, w których nadrzędny charakter
            wobec tych interesów mają interesy lub podstawowe prawa i wolności
            osoby, której dane dotyczą, wymagające ochrony danych osobowych, w
            szczególności gdy osoba, której dane dotyczą, jest dzieckiem.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.2.Przetwarzanie danych osobowych przez Administratora wymaga
            każdorazowo zaistnienia co najmniej jednej z podstaw wskazanych w
            pkt. 2.1 polityki prywatności. Konkretne podstawy przetwarzania
            danych osobowych Usługobiorców i Klientów Serwisu Internetowego
            przez Administratora są wskazane w kolejnym punkcie polityki
            prywatności – w odniesieniu do danego celu przetwarzania danych
            osobowych przez Administratora.
          </p>
          <p className="text-black font-bold mt-2 text-lg">
            3. CEL, PODSTAWA, OKRES I ZAKRES PRZETWARZANIA DANYCH W SERWISIE
            INTERNETOWYM
          </p>
          <p className="text-black text-base ml-5">
            3.1.Każdorazowo cel, podstawa, okres i zakres oraz odbiorcy danych
            osobowych przetwarzanych przez Administratora wynika z działań
            podejmowanych przez danego Usługobiorcę w Serwisie Internetowym.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            3.2.Administrator może przetwarzać dane osobowe w Serwisie
            Internetowym w następujących celach, na następujących podstawach, w
            okresach oraz w następującym zakresie:
          </p>

          <table
            border="1"
            style={{
              width: "100%",
              marginTop: "0.5rem",
              textAlign: "center",
              borderCollapse: "collapse",
            }}
            className="ml-5"
          >
            <thead>
              <tr>
                <th className="border border-[#000]">
                  Cel przetwarzania danych
                </th>
                <th className="border border-[#000]">
                  Podstawa prawna przetwarzania i okres przechowywania danych
                </th>
                <th className="border border-[#000]">
                  Zakres przetwarzanych danych
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-[#000] p-2">
                  Wykonanie umowy o świadczenie Usługi Elektronicznej, udział w
                  programie współpracy (afiliacyjnym) lub podjęcie działań na
                  żądanie osoby, której dane dotyczą, przed zawarciem w/w umów
                </td>
                <td className="border border-[#000] p-2">
                  Artykuł 6 ust. 1 lit. b) Rozporządzenia RODO (wykonanie umowy)
                  Dane są przechowywane przez okres niezbędny do wykonania,
                  rozwiązania lub wygaśnięcia w inny sposób zawartej umowy.
                </td>
                <td className="border border-[#000] p-2">
                  Zakres maksymalny: imię i nazwisko; adres poczty
                  elektronicznej Usługobiorcy.
                </td>
              </tr>
              <tr>
                <td className="border border-[#000] p-2">
                  Marketing usług i produktów Administratora
                </td>
                <td className="border border-[#000] p-2">
                  Artykuł 6 ust. 1 lit. a) Rozporządzenia RODO (zgoda) Dane
                  przechowywane są do momentu wycofania zgody przez osobę,
                  której dane dotyczą na dalsze przetwarzanie jej danych w tym
                  celu.
                </td>
                <td className="border border-[#000] p-2">
                  Zakres maksymalny: imię i nazwisko, adres poczty
                  elektronicznej.
                </td>
              </tr>
              <tr>
                <td className="border border-[#000] p-2">
                  Wystawianie faktur dla klientów
                </td>
                <td className="border border-[#000] p-2">
                  Artykuł 6 ust. 1 lit. c) Rozporządzenia RODO w zw. ustawą z
                  dnia 11.03.2004 r. o podatku od towarów i usług. Dane są
                  przechowywane przez okres wymagany przepisami prawa
                  nakazującymi Administratorowi przechowywanie ksiąg
                  rachunkowych (5 lat, licząc od początku roku następującego po
                  roku obrotowym, którego dane dotyczą).
                </td>
                <td className="border border-[#000] p-2">
                  Imię i nazwisko; adres e-mail, adres zamieszkania, adres
                  prowadzenia działalności/siedziby, nazwa firmy oraz numer
                  identyfikacji podatkowej (NIP) Usługobiorcy/kod firmy.
                </td>
              </tr>
              <tr>
                <td className="border border-[#000] p-2">
                  Prowadzenie ksiąg rachunkowych
                </td>
                <td className="border border-[#000] p-2">
                  Artykuł 6 ust. 1 lit. c) Rozporządzenia RODO w zw. z art. 74
                  ust. 2 ustawy o rachunkowości tj. z dnia 30 stycznia 2018 r.
                  (Dz.U. z 2018 r. poz. 395) Dane są przechowywane przez okres
                  wymagany przepisami prawa nakazującymi Administratorowi
                  przechowywanie ksiąg rachunkowych (5 lat, licząc od początku
                  roku następującego po roku obrotowym, którego dane dotyczą).
                </td>
                <td className="border border-[#000]">
                  Imię i nazwisko; adres prowadzenia działalności/siedziby,
                  nazwa firmy oraz numer identyfikacji podatkowej (NIP)
                  Usługobiorcy.
                </td>
              </tr>
              <tr>
                <td className="border border-[#000] p-2">
                  Ustalenie, dochodzenie lub obrona roszczeń jakie może podnosić
                  Administrator lub jakie mogą być podnoszone wobec
                  Administratora
                </td>
                <td className="border border-[#000] p-2">
                  Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO Dane są
                  przechowywane przez okres istnienia prawnie uzasadnionego
                  interesu realizowanego przez Administratora, nie dłużej jednak
                  niż przez okres przedawnienia roszczeń w stosunku do osoby,
                  której dane dotyczą, z tytułu prowadzonej przez Administratora
                  działalności gospodarczej. Okres przedawnienia określają
                  przepisy prawa, w szczególności kodeksu cywilnego (podstawowy
                  termin przedawnienia dla roszczeń związanych z prowadzeniem
                  działalności gospodarczej wynosi trzy lata, a dla umowy
                  sprzedaży lub świadczenia usług dwa lata).
                </td>
                <td className="border border-[#000] p-2">
                  Zakres maksymalny: imię i nazwisko, adres poczty
                  elektronicznej, numer telefonu kontaktowego, nazwa firmy,
                  adres prowadzenia działalności/siedziby oraz numer
                  identyfikacji podatkowej (NIP) Usługobiorcy.
                </td>
              </tr>
              <tr>
                <td className="border border-[#000] p-2">
                  Korzystanie ze strony Serwisu Internetowego i zapewnienie jej
                  prawidłowego działania
                </td>
                <td className="border border-[#000] p-2">
                  Artykuł 6 ust. 1 lit. f) Rozporządzenia RODO (prawnie
                  uzasadniony interes administratora) – przetwarzanie jest
                  niezbędne do celów wynikających z prawnie uzasadnionych
                  interesów Administratora – polegających na prowadzeniu i
                  utrzymaniu strony Serwisu Internetowego
                </td>
                <td className="border border-[#000] p-2">
                  Dane są przechowywane przez okres istnienia prawnie
                  uzasadnionego interesu realizowanego przez Administratora, nie
                  dłużej jednak niż przez okres przedawnienia roszczeń
                  Administratora w stosunku do osoby, której dane dotyczą, z
                  tytułu prowadzonej przez Administratora działalności
                  gospodarczej. Okres przedawnienia określają przepisy prawa, w
                  szczególności Kodeksu Cywilnego (podstawowy termin
                  przedawnienia dla roszczeń związanych z prowadzeniem
                  działalności gospodarczej wynosi trzy lata, a dla umowy
                  sprzedaży lub świadczenia usług dwa lata).
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-black font-bold mt-2 text-lg">
            4. ODBIORCY DANYCH W SERWISIE INTERNETOWYM
          </p>
          <p className="text-black text-base ml-5">
            4.1.Dla prawidłowego funkcjonowania Serwisu Internetowego, w tym dla
            prawidłowego świadczenia przez Administratora Usług Elektronicznych
            konieczne jest korzystanie przez Administratora z usług podmiotów
            zewnętrznych (takich jak np. dostawca oprogramowania). Administrator
            korzysta wyłącznie z usług takich podmiotów przetwarzających, którzy
            zapewniają wystarczające gwarancje wdrożenia odpowiednich środków
            technicznych i organizacyjnych, tak by przetwarzanie spełniało
            wymogi Rozporządzenia RODO i chroniło prawa osób, których dane
            dotyczą.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            4.2.Przekazanie danych przez Administratora nie następuje w każdym
            wypadku i nie do wszystkich wskazanych w polityce prywatności
            odbiorców lub kategorii odbiorców – Administrator przekazuje dane
            wyłącznie wtedy, gdy jest to niezbędne do realizacji danego celu
            przetwarzania danych osobowych i tylko w zakresie niezbędnym do jego
            zrealizowania.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            4.3.Dane osobowe mogą być przekazywane przez Administratora do
            państwa trzeciego, przy czym Administrator zapewnia, że w takim
            przypadku odbywać się to będzie w stosunku do państwa zapewniającego
            odpowiedni stopień ochrony – zgodny z Rozporządzeniem RODO, a osoba
            której dane dotyczą ma możliwość uzyskania kopii swoich danych.
            Administrator przekazuje zebrane dane osobowe jedynie w przypadku
            oraz w zakresie niezbędnym do zrealizowania danego celu
            przetwarzania danych zgodnego z niniejszą polityką prywatności.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            4.4.Dane osobowe Usługobiorców Serwisu Internetowego mogą być
            przekazywane następującym odbiorcom lub kategoriom odbiorców:
          </p>
          <p className="text-black text-base ml-10 mt-2">
            a. podmioty obsługujące płatności elektroniczne lub kartą płatniczą
            - w przypadku Usługobiorcy, który korzysta w Serwisie Internetowym
            ze sposobu płatności elektronicznych lub kartą płatniczą
            Administrator udostępnia zebrane dane osobowe Usługobiorcy wybranemu
            podmiotowi obsługującemu powyższe płatności w Serwisie Internetowym
            na zlecenie Administratora w zakresie niezbędnym do obsługi
            płatności realizowanej przez Usługobiorcę.
          </p>
          <p className="text-black text-base ml-10 mt-2">
            b. dostawcy usług zaopatrujący Administratora w rozwiązania
            techniczne, informatyczne oraz organizacyjne, umożliwiające
            Administratorowi prowadzenie działalności gospodarczej, w tym
            Serwisu Internetowego i świadczonych za jego pośrednictwem Usług
            Elektronicznych (w szczególności dostawcy oprogramowania
            komputerowego do prowadzenia Serwisu Internetowego, dostawcy poczty
            elektronicznej i hostingu oraz dostawcy oprogramowania do
            zarządzania firmą i udzielania pomocy technicznej Administratorowi)
            - Administrator udostępnia zebrane dane osobowe Usługobiorcy
            wybranemu dostawcy działającemu na jego zlecenie jedynie w przypadku
            oraz w zakresie niezbędnym do zrealizowania danego celu
            przetwarzania danych zgodnego z niniejszą polityką prywatności.
          </p>
          <p className="text-black text-base ml-10 mt-2">
            c. podmiotom lub organom publicznym w związku ze zwalczaniem
            naruszeń prawa, oszustwami i nadużyciami.
          </p>

          <p className="text-black font-bold mt-2 text-lg ">
            5. PRAWA OSOBY, KTÓREJ DANE DOTYCZĄ
          </p>
          <p className="text-black text-base ml-5">
            5.1.Prawo dostępu, sprostowania, ograniczenia, usunięcia lub
            przenoszenia - osoba, której dane dotyczą, ma prawo żądania od
            Administratora dostępu do swoich danych osobowych, ich sprostowania,
            usunięcia („prawo do bycia zapomnianym”) lub ograniczenia
            przetwarzania oraz ma prawo do wniesienia sprzeciwu wobec
            przetwarzania, a także ma prawo do przenoszenia swoich danych.
            Szczegółowe warunki wykonywania wskazanych wyżej praw wskazane są w
            art. 15-21 Rozporządzenia RODO.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            5.2.Prawo do cofnięcia zgody w dowolnym momencie – osoba, której
            dane przetwarzane są przez Administratora na podstawie wyrażonej
            zgody (na podstawie art. 6 ust. 1 lit. a) lub art. 9 ust. 2 lit. a)
            Rozporządzenia RODO), to ma ona prawo do cofnięcia zgody w dowolnym
            momencie bez wpływu na zgodność z prawem przetwarzania, którego
            dokonano na podstawie zgody przed jej cofnięciem.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            5.3.Prawo wniesienia skargi do organu nadzorczego – osoba, której
            dane przetwarzane są przez Administratora, ma prawo wniesienia
            skargi do organu nadzorczego w sposób i trybie określonym w
            przepisach Rozporządzenia RODO oraz prawa polskiego, w szczególności
            ustawy o ochronie danych osobowych. Organem nadzorczym w Polsce jest
            Prezes Urzędu Ochrony Danych Osobowych.
          </p>
        </div>{" "}
      </PublicPageLayout>{" "}
    </>
  );
};
export default PrivacyPolicy;
