import PublicPageLayout from "../layouts/PublicPage";

const TermsOfService = () => {
  return (
    <>
      <PublicPageLayout>
        <p className="pt-sans-bold text-3xl text-center">Regulamin serwisu</p>
        <div className="p-6">
          <p className="text-black font-bold text-lg">Spis treści:</p>
          <p className="text-black text-base ml-5">1. POSTANOWIENIA OGÓLNE</p>
          <p className="text-black text-base ml-5">
            2. USŁUGI ELEKTRONICZNE W SERWISIE INTERNETOWYM
          </p>
          <p className="text-black text-base ml-5">
            3. SZCZEGÓŁOWE WARUNKI DOTYCZĄCE RAPORTU
          </p>
          <p className="text-black text-base ml-5">4. SPOSOBY PŁATNOŚCI</p>
          <p className="text-black text-base ml-5">
            5. WARUNKI ROZWIĄZYWANIA UMÓW O ŚWIADCZENIE USŁUG ELEKTRONICZNYCH
          </p>
          <p className="text-black text-base ml-5">
            6. TRYB POSTĘPOWANIA REKLAMACYJNEGO
          </p>
          <p className="text-black text-base ml-5">
            7. PRAWO ODSTĄPIENIA OD UMOWY
          </p>
          <p className="text-black text-base ml-5">
            8. POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA
            ROSZCZEŃ ORAZ ZASADY DOSTĘPU DO TYCH PROCEDUR
          </p>
          <p className="text-black text-base ml-5">9. DANE OSOBOWE</p>
          <p className="text-black text-base ml-5">10. PRAWA AUTORSKIE</p>
          <p className="text-black text-base ml-5">
            11. POSTANOWIENIA DOTYCZĄCE FIRM
          </p>
          <p className="text-black text-base ml-5">12. POSTANOWIENIA KOŃCOWE</p>

          <p className="text-black font-bold mt-2 text-lg ">
            1. POSTANOWIENIA OGÓLNE
          </p>
          <p className="text-black text-base ml-5">
            1.1. Serwis Internetowy dostępny pod adresem internetowym{" "}
            <a href="https://checkcar.site/" className="text-blue-500">
              https://checkcar.site/
            </a>{" "}
            prowadzony jest przez osobę fizyczną; adres poczty elektronicznej:{" "}
            <a href="mailto:car_site@tutamail.com" className="text-blue-500">
              car_site@tutamail.com
            </a>
            , adres e-mail jest dostępny na stronach Serwisu Internetowego.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.2. Postanowienia niniejszego Regulaminu nie mają na celu wyłączać
            ani ograniczać jakichkolwiek praw Usługobiorcy będącego jednocześnie
            konsumentem lub osobą fizyczną, do której stosuje się przepisy
            dotyczące konsumenta, przysługujących im na mocy bezwzględnie
            wiążących przepisów prawa. W przypadku niezgodności postanowień
            niniejszego Regulaminu z powyższymi przepisami, pierwszeństwo mają
            przepisy.
          </p>
          <p className="text-black text-base ml-5 mt-2">1.3. Definicje:</p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.1. Raport - informacje pochodzące z Carfax i Autocheck zarówno
            dealerom samochodowym, jak i konsumentom chcącym kupić używane
            pojazdy. Dostarczane przez nas raporty historii pojazdu obejmują
            dane z baz danych CARFAX i Autocheck. Dodatkowo, jeśli pojazd został
            sprzedany na aukcji samochodowej, nasze raporty mogą zawierać
            informacje z bazy danych aukcji, w tym zdjęcia i szczegółowe
            informacje dostępne publicznie w okresie sprzedaży.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.2. USTAWA O PRAWACH KONSUMENTA – ustawa z dnia 30 maja 2014 r. o
            prawach konsumenta (Dz.U. 2014 poz. 827 ze zm.).
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.3. HotPay - system internetowy obsługujący płatności
            elektroniczne - ePłatności spółka z ograniczoną odpowiedzialnością
            spółka komandytowa z siedzibą w Andrychowie (34-120), przy ul. 27
            stycznia 9, wpisaną do Krajowego Rejestru Sądowego prowadzonego
            przez Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie, XI Wydział
            Gospodarczy Krajowego Rejestru Sądowego pod numerem: 000655383, NIP:
            5512627897, REGON: 366165170, kapitał zakładowy: 50 000 zł.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.4. KODEKS CYWILNY – ustawa kodeks cywilny z dnia 23 kwietnia
            1964 r. (Dz.U.1964 Nr 16, poz. 93 z późn zm.).
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.5. KONTO – Usługa Elektroniczna, oznaczony indywidualną nazwą
            (adres e-mail) i hasłem podanym przez Usługobiorcę zbiór zasobów w
            systemie teleinformatycznym Usługodawcy, w którym gromadzone są dane
            Usługobiorcy.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.6. NUMER VIN – unikalny numer identyfikacyjny właściwy dla
            danego pojazdu mechanicznego, składający się z maksymalnie 17 znaków
            (liter i cyfr) (Vehicle Identification Number).
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.7. REGULAMIN – niniejszy regulamin Serwisu Internetowego.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.8. SERWIS INTERNETOWY - Serwis internetowy Usługodawcy dostępny
            pod adresem internetowym{" "}
            <a href="https://checkcar.site" className="text-blue-500">
              https://checkcar.site
            </a>
            .
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.9. USŁUGA ELEKTRONICZNA – usługa świadczona drogą elektroniczną
            przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem Serwisu
            Internetowego.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.10. USŁUGOBIORCA – osoba fizyczna posiadająca pełną zdolność do
            czynności prawnych, osoba prawna albo jednostka organizacyjna
            nieposiadająca osobowości prawnej, która korzysta z Usługi
            Elektronicznej zgodnie z Regulaminem.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            1.3.11. USŁUGODAWCA – osobę fizyczną; adres poczty elektronicznej:{" "}
            <a href="mailto:car_site@tutamail.com" className="text-blue-500">
              car_site@tutamail.com
            </a>
            , numer telefonu kontaktowego: oraz adres e-mail jest dostępny na
            stronach Serwisu Internetowego.
          </p>

          <p className="text-black font-bold mt-2 text-lg ">
            2. USŁUGI ELEKTRONICZNE W SERWISIE INTERNETOWYM
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.1.1. W Serwisie Internetowym dostępne są następujące Usługi
            Elektroniczne: Raport, Konto.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.1.2. Raport – Udostępnienie wybranych Raportów następuje po
            spełnieniu łącznie trzech kolejnych kroków – (1) dokonaniu płatności
            celem doładowania konta użytkownika (2) wprowadzeniu numeru VIN (3)
            kliknięciu pola „Check”. Wprowadzenie numeru VIN wymaga zalogowania
            się na swoje Konto w Serwisie Internetowym, a w przypadku jego braku
            możliwe jest utworzenie Konta i zalogowanie się na nie.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.1.3. Konto - korzystanie z Konta możliwe jest po spełnieniu
            łącznie dwóch kolejnych kroków – (1) wypełnieniu formularza
            rejestracji (2) oraz kliknięciu pola „Sign Up”. W formularzu
            rejestracji niezbędne jest podanie następujących danych
            Usługobiorcy: full name (Nickname), adres poczty elektronicznej oraz
            hasło.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.1.4. Usługobiorca obowiązany jest do korzystania z Serwisu
            Internetowego w sposób zgodny z prawem i dobrymi obyczajami mając na
            uwadze poszanowanie dóbr osobistych i praw własności intelektualnej
            osób trzecich. Zabronione są bezprawne działania mogące utrudniać
            funkcjonowanie Serwisu Internetowego lub narazić Usługodawcę na
            utratę renomy.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.2. Usługobiorcę obowiązuje zakaz dostarczania treści o charakterze
            bezprawnym. Usługobiorca obowiązany jest do wprowadzania danych
            zgodnych ze stanem faktycznym.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.3. Wymagania techniczne niezbędne do współpracy z systemem
            teleinformatycznym, którym posługuje się Usługodawca:
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.3.1. Komputer, tablet, telefon lub inne urządzenie multimedialne z
            dostępem do Internetu.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.3.2. Przeglądarka internetowa zainstalowana na urządzeniu z
            dostępem do Internetu w aktualnej wersji: Mozilla Firefox, Internet
            Explorer, Opera, Google Chrome, Microsoft Edge, Safarii.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.3.3. Włączona w przeglądarce internetowej obsługa plików Cookies.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.3.4. Włączona obsługa skryptów JavaScript w twoje przeglądarce.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.4. Konto świadczone jest nieodpłatnie.
          </p>
          <p className="text-black text-base ml-5 mt-2">
            2.5. Udostępnianie Raportów jest odpłatne – cena Raportu podana jest
            na stronie internetowej Serwisu Internetowego. Cena Raportu podane
            są w złotych polskich. Cena ta jest ceną łączną wraz z podatkami i
            wszelkimi innymi opłatami.
          </p>

          <p className="text-black font-bold mt-2 text-lg ">
            3. SZCZEGÓŁOWE WARUNKI DOTYCZĄCE RAPORTU
          </p>
          <p className="text-black text-base ml-5">
            3.1. Koszt Raportu oraz rodzaje dostępnych Raportów podane na
            stronie internetowej Serwisu Internetowego. Do udostępnienia Raportu
            niezbędne jest doładowanie konta użytkownika środkami pieniężnymi
            poprzez Hotpay oraz wprowadzenie numeru VIN.
          </p>
          <p className="text-black text-base ml-5 ">
            3.2. Raport ma charakter pomocniczy (dodatkowy) i nie powinien
            stanowić jedynej podstawy decyzji o zawarciu albo nie zawarciu umowy
            sprzedaży Pojazdu przez Usługobiorcę. W razie powzięcia
            jakichkolwiek wątpliwości co do pochodzenia Pojazdu, jego stanu
            faktycznego, w tym przebiegu lub historii Usługodawca stanowczo
            zaleca je zweryfikować z odpowiednimi organami państwa, właścicielem
            Pojazdu lub innymi osobami trzecimi udzielającymi pomocy w tym
            zakresie (np. stacja kontroli pojazdów, ASO – autoryzowana stacja
            obsługi, rzeczoznawca samochodowy). Usługodawca zaleca w każdym
            wypadku (nawet mimo braku wątpliwości i niezależnie od treści
            Raportu) kontakt z powyższymi organami i osobami celem weryfikacji
            Pojazdu przed podjęciem decyzji o zawarciu umowy sprzedaży.
          </p>
          <p className="text-black text-base ml-5 ">
            3.3. Usługodawca zbiera dane z wielu zewnętrznych i niezależnych od
            Usługodawcy źródeł w celu wykorzystania ich do udostępnienia w
            Raporcie. Źródła, z których Usługodawca zbiera dane korzystają z
            różnych narzędzi teleinformatycznych, gromadzą one dane w różnym
            trybie i terminach, a dodatkowo na samo udostępnienie danych w
            ramach Raportu mogą mieć wpływ okoliczności związane z ich
            przekazywaniem w momencie udostępniania Raportu (w tym siła wyższa i
            poziom jakości usług teletransmisyjnych po stronie źródła danych),
            co w niektórych przypadkach może powodować niezgodność danych. Z
            tego powodu Usługodawca wskazuje, że nie wszystkie informacje i
            zdarzenia o Pojazdach mogą być dostępne, kompletne i poprawne w
            ramach IS lub Raportu. Informacje w IS i Raporcie mają charakter
            pomocniczy i nie powinny stanowić jedynej podstawy decyzji o
            zawarciu albo nie zawarciu umowy sprzedaży Pojazdu przez
            Usługobiorcę. Usługodawca wskazuje ponadto, że z w/w powodów
            informacje i zdarzenia o Pojazdach zawarte w Raporcie nie mogą
            jednoznacznie w każdym przypadku określać stanu faktycznego pojazdu.
            Raport przedstawia historię pojazdu (informacje i zdarzenia o
            Pojeździe) ustalaną na podstawie danych z wielu zewnętrznych i
            niezależnych od Usługodawcy źródeł, aczkolwiek ze wskazanych wyżej
            powodów historia ta nie musi być zgodna ze stanem faktycznym
            Pojazdu. Dlatego Usługodawca zaleca w każdym wypadku (nawet mimo
            braku wątpliwości i niezależnie od treści Raportu) kontakt z
            odpowiednimi organami państwa, właścicielem Pojazdu lub innymi
            osobami trzecimi udzielającymi pomocy w tym zakresie (np. stacja
            kontroli pojazdów, ASO – autoryzowana stacja obsługi, rzeczoznawca
            samochodowy) celem weryfikacji stanu faktycznego Pojazdu przed
            podjęciem decyzji o zawarciu umowy sprzedaży. Decyzja o zakupie
            danego Pojazdu należy do Usługobiorcy.
          </p>
          <p className="text-black text-base ml-5 ">
            3.4. Zakres informacji i zdarzeń o Pojazdach udostępniany w ramach
            Raportu to dostępny zakres maksymalny dla Usługodawcy na moment
            udostępnienia Raportu – zależny od egzemplarza Pojazdu, jego
            producenta, modelu, roku i miejsca produkcji oraz marki -
            Usługobiorca jest każdorazowo informowany przed udostępnieniem
            danego Raportu w ramach zakresie i ilości dostępnych informacji lub
            zdarzeń ewentualnie o braku dostępności. Na podstawie usługobiorca
            podejmuje decyzję co do udostępnienia Raportu oraz jego rodzaju albo
            rezygnuje z jego udostępnienia.
          </p>

          <p className="text-black text-base ml-5 ">
            3.5. Opis produktu cyfrowego - Raportu: Raport oferowany przez
            Usługodawcę poprzez stronę Serwisu Internetowego Checkcar.site
          </p>
          <p className="text-black text-base ml-5">
            3.5.1. Główne cechy Raportu: Usługa Elektroniczna polegająca na
            udostępnieniu informacji i zdarzeń o Pojeździe w postaci
            elektronicznego raportu na podstawie danych pozyskiwanych z wielu
            zewnętrznych i niezależnych od Usługodawcy źródeł dostępnych w
            Serwisie Internetowym i zgodnie ze stanem tych źródeł dostępnym dla
            Usługodawcy na moment udostępnienia Raportu.
          </p>

          <p className="text-black text-base ml-5">
            3.5.2. Cena: Cena udostępniana jest na stronie Serwisu
            Internetowego. O łącznej cenie wraz z podatkami oraz o innych
            kosztach, a gdy nie można ustalić wysokości tych opłat – o obowiązku
            ich uiszczenia, Usługobiorca jest każdorazowo informowany na
            stronach Serwisu Internetowego w trakcie składania zamówienia przed
            dokonaniem zakupu Raportu. Cena za dany raport jest w złotych
            polskich określana z angielskiego jako „Price".
          </p>

          <p className="text-black text-base mt-2 ml-5">
            3.6.3. Funkcjonalność:
          </p>
          <p className="text-black text-base ml-10">Język: Angielski</p>
          <p className="text-black text-base ml-10">Typ pliku: webpage</p>
          <p className="text-black text-base ml-10">
            Rozmiar: Rozmiar uzależniony jest od ilości informacji znajdujących
            się w raporcie
          </p>
          <p className="text-black text-base ml-10">
            Rodzaj dostępu: pobranie, udostępnienie Raportu następuje
            niezwłocznie po jego zakupie, nie później niż w ciągu 1 dnia
            kalendarzowego (standardowo do 5 minut) od momentu otrzymania przez
            Usługodawcę potwierdzenia płatności od wybranego operatora płatności
            dostępnego w Serwisie Internetowym.
          </p>
          <p className="text-black text-base ml-10">
            Warunki dostępu: nieograniczone korzystanie na własny użytek.
          </p>
          <p className="text-black text-base ml-10">Ograniczenia: brak</p>

          <p className="text-black text-base mt-2 ml-5">
            3.6.4. Interoperacyjność:
          </p>
          <p className="text-black text-base ml-10">
            Sprzęt i oprogramowanie: Komputer, tablet, telefon lub inne
            urządzenie multimedialne z dostępem do Internetu. Przeglądarka
            internetowa zainstalowana na urządzeniu z dostępem do Internetu w
            aktualnej wersji: Mozilla Firefox, Internet Explorer, Opera, Google
            Chrome, Microsoft Edge, Safarii. Włączona w przeglądarce
            internetowej obsługa plików Cookies.
          </p>

          <p className="text-black font-bold mt-4 text-lg">
            4. SPOSOBY PŁATNOŚCI
          </p>
          <p className="text-black text-base mt-2 ml-5">
            4.1. Usługodawca udostępnia następujące sposoby płatności:
          </p>
          <p className="text-black text-base ml-5">
            4.1.1. Płatności elektroniczne za pośrednictwem serwisu Hotpay
            możliwe sposoby płatności dostępne są na stronie Serwisu
            Internetowego przed dokonywaniem płatności oraz na stronie
            internetowej https://hotpay.pl/
          </p>
          <p className="text-black text-base ml-5">
            4.1.2. Usługobiorca obowiązany jest do dokonania płatności w
            terminie 3 dni kalendarzowych od dnia zawarcia umowy, z
            zastrzeżeniem, że dostarczenie Raportu możliwe jest dopiero po
            uiszczeniu płatności przez Usługobiorcę.
          </p>

          <p className="text-black font-bold mt-4 text-lg">
            5. WARUNKI ROZWIĄZYWANIA UMÓW O ŚWIADCZENIE USŁUG ELEKTRONICZNYCH
          </p>
          <p className="text-black text-base mt-2 ml-5">
            5.1. Usługodawca i Usługobiorca mogą rozwiązać umowę o świadczenie
            usług drogą elektroniczną w każdym czasie w drodze porozumienia
            stron.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            5.2. Wypowiedzenie umowy o świadczenie Usługi Elektronicznej
            zawartej na czas nieoznaczony o charakterze ciągłym (np. Konto):
          </p>
          <p className="text-black text-base ml-5">
            5.2.1. Usługobiorca może wypowiedzieć umowę ze skutkiem
            natychmiastowym i bez wskazania przyczyn poprzez przesłanie
            stosownego oświadczenia na przykład za pomocą wysłania oświadczenia
            na adres poczty elektronicznej: car_site@tutamail.com
          </p>

          <p className="text-black font-bold mt-4 text-lg">
            6. TRYB POSTĘPOWANIA REKLAMACYJNEGO
          </p>
          <p className="text-black text-base mt-2 ml-5">
            6.1. Niniejszy punkt 6.1 Regulaminu określa procedurę rozpatrywania
            reklamacji wspólną dla wszystkich reklamacji składanych do
            Usługodawcy, w szczególności reklamacji dotyczących Raportów i
            innych Usług Elektronicznych oraz Serwisu Internetowego.
          </p>
          <p className="text-black text-base ml-5">
            6.1.1. Reklamacje można składać na przykład poprzez wysłanie
            wiadomości na adres podany na stronie https://checkcar.site/support
            na adres poczty elektronicznej: car_site@tutamail.com
          </p>
          <p className="text-black text-base ml-5">
            6.1.2. Usługodawca zaleca podanie w opisie reklamacji: (1)
            informacji i okoliczności dotyczących przedmiotu reklamacji, w
            szczególności rodzaju i daty wystąpienia nieprawidłowości lub braku
            zgodności z umową; (2) żądania sposobu doprowadzenia do zgodności z
            umową lub oświadczenia o obniżeniu ceny albo odstąpieniu od umowy
            lub innego roszczenia; oraz (3) danych kontaktowych składającego
            reklamację – ułatwi to i przyspieszy rozpatrzenie reklamacji.
          </p>
          <p className="text-black text-base ml-5">
            6.1.3. W razie zmiany podanych danych kontaktowych przez
            składającego reklamację w trakcie rozpatrywania reklamacji
            zobowiązany jest on do powiadomienia o tym Usługodawcy.
          </p>
          <p className="text-black text-base ml-5">
            6.1.4. Do reklamacji mogą zostać załączone przez składającego
            reklamację dowody (np. zdjęcia, zrzuty ekranu lub dokumenty)
            związane z przedmiotem reklamacji. Usługodawca może także zwrócić
            się do składającego reklamację z prośbą o podanie dodatkowych
            informacji lub przesłanie dowodów (np. zdjęcia, zrzuty ekranu),
            jeżeli ułatwi to i przyspieszy rozpatrzenie reklamacji przez
            Usługodawcę.
          </p>
          <p className="text-black text-base ml-5">
            6.1.5. Usługodawca ustosunkuje się do reklamacji niezwłocznie, nie
            później niż w terminie 14 dni kalendarzowych od dnia jej otrzymania.
          </p>
          <p className="text-black text-base ml-5">
            6.2. Podstawa i zakres odpowiedzialności ustawowej Usługodawcy są
            określone powszechnie obowiązującymi przepisami prawa, w
            szczególności w Kodeksie Cywilnym, Ustawie o Prawach Konsumenta oraz
            ustawie o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002
            r. (Dz.U. Nr 144, poz. 1204 ze zm.).
          </p>
          <p className="text-black text-base ml-5">
            6.2.1. Usługodawca wskazuje, że przewidzianą przez prawo
            odpowiedzialność Usługodawcy za zgodność świadczenia z umową w
            przypadku reklamacji treści lub usługi cyfrowej lub rzeczy ruchomej,
            która służy wyłącznie jako nośnik treści cyfrowej – zakupionej przez
            konsumenta na podstawie umowy zawartej z Usługodawcą od dnia 1.
            stycznia 2023 r. lub przed tym dniem, jeżeli dostarczanie takiej
            treści lub usługi cyfrowej miało nastąpić lub nastąpiło po tym dniu
            – określają przepisy Ustawy o Prawach Konsumenta w brzmieniu
            obowiązującym od dnia 1. stycznia 2023 r., w szczególności art. 43h
            – 43q Ustawy o Prawach Konsumenta. Przepisy te określają w
            szczególności podstawę i zakres odpowiedzialności Usługodawcy
            względem konsumenta, w razie braku zgodności świadczenia z umową.
          </p>
          <p className="text-black font-bold mt-4 text-lg">
            7. PRAWO ODSTĄPIENIA OD UMOWY
          </p>
          <p className="text-black text-base mt-2 ml-5">
            7.1. Usługobiorca będący jednocześnie konsumentem lub osobą
            fizyczną, do której stosuje się przepisy dotyczące konsumenta, który
            zawarł z Usługodawcą umowę na odległość, może od niej odstąpić bez
            podania przyczyn i bez ponoszenia kosztów (z zastrzeżeniem pkt. 7.4.
            Regulaminu), składając stosowne oświadczenie w terminie czternastu
            dni. Do zachowania tego terminu wystarczy wysłanie oświadczenia
            przed jego upływem. Oświadczenie o odstąpieniu od umowy może zostać
            złożone na przykład: na adres poczty elektronicznej:{" "}
            <a href="mailto:car_site@tutamail.com" className="text-blue-500">
              car_site@tutamail.com
            </a>
          </p>

          <p className="text-black text-base mt-2 ml-5">
            7.2. Bieg terminu do odstąpienia od umowy rozpoczyna się: (1) dla
            umowy, w wykonaniu której przedsiębiorca wydaje rzecz, będąc
            zobowiązany do przeniesienia jej własności - od objęcia rzeczy w
            posiadanie przez konsumenta lub wskazaną przez niego osobę trzecią
            inną niż przewoźnik, a w przypadku umowy, która: (a) obejmuje wiele
            rzeczy, które są dostarczane osobno, partiami lub w częściach - od
            objęcia w posiadanie ostatniej rzeczy, partii lub części, (b) polega
            na regularnym dostarczaniu rzeczy przez czas oznaczony - od objęcia
            w posiadanie pierwszej z rzeczy; (2) dla pozostałych umów - od dnia
            zawarcia umowy.
          </p>

          <p className="text-black text-base mt-2 ml-5">
            7.3. W przypadku odstąpienia od umowy zawartej na odległość umowę
            uważa się za niezawartą.
          </p>

          <p className="text-black text-base mt-2 ml-5">
            7.4. W przypadku usługi której wykonywanie – na wyraźne żądanie
            konsumenta lub osoby fizycznej, do której stosuje się przepisy
            dotyczące konsumenta – rozpoczęło się przed upływem terminu do
            odstąpienia od umowy, konsument lub osoba fizyczna do której stosuje
            się przepisy dotyczące konsumenta, która wykonuje prawo odstąpienia
            od umowy po zgłoszeniu takiego żądania, ma obowiązek zapłaty za
            świadczenia spełnione do chwili odstąpienia od umowy. Kwotę zapłaty
            oblicza się proporcjonalnie do zakresu spełnionego świadczenia, z
            uwzględnieniem uzgodnionej w umowie ceny lub wynagrodzenia. Jeżeli
            cena lub wynagrodzenie są nadmierne, podstawą obliczenia tej kwoty
            jest wartość rynkowa spełnionego świadczenia.
          </p>

          <p className="text-black text-base mt-2 ml-5">
            7.5. Prawo odstąpienia od umowy zawartej na odległość nie
            przysługuje konsumentowi oraz odpowiednio osobie fizycznej, do
            której stosuje się przepisy dotyczące konsumenta w wypadkach: (1)
            umów o świadczenie usług, za które konsument jest zobowiązany do
            zapłaty ceny, jeżeli przedsiębiorca wykonał w pełni usługę za
            wyraźną i uprzednią zgodą konsumenta, który został poinformowany
            przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez
            przedsiębiorcę utraci prawo odstąpienia od umowy, i przyjął to do
            wiadomości; (2) umów, w której przedmiotem świadczenia jest towar
            nieprefabrykowany, wyprodukowany według specyfikacji konsumenta lub
            służący zaspokojeniu jego zindywidualizowanych potrzeb; (3) umów, w
            której przedmiotem świadczenia jest towar ulegający szybkiemu
            zepsuciu lub mający krótki termin przydatności do użycia; (4) umów o
            dostarczanie treści cyfrowych niedostarczanych na nośniku
            materialnym, za które konsument jest zobowiązany do zapłaty ceny,
            jeżeli przedsiębiorca rozpoczął świadczenie za wyraźną i uprzednią
            zgodą konsumenta, który został poinformowany przed rozpoczęciem
            świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę
            utraci prawo odstąpienia od umowy, i przyjął to do wiadomości, a
            przedsiębiorca przekazał konsumentowi potwierdzenie, o którym mowa w
            art. 15 ust. 1 i 2 albo art. 21 ust. 1 Ustawy o Prawach Konsumenta.
          </p>
          <p className="text-black font-bold mt-4 text-lg">
            8. POZASĄDOWE SPOSOBY ROZPATRYWANIA REKLAMACJI I DOCHODZENIA
            ROSZCZEŃ ORAZ ZASADY DOSTĘPU DO TYCH PROCEDUR
          </p>
          <p className="text-black text-base mt-2 ml-5">
            8.1. Niniejszy punkt Regulaminu ma zastosowanie jedynie do
            Usługobiorców będących konsumentami.
          </p>

          <p className="text-black text-base mt-2 ml-5">
            8.2. Metody rozwiązywania sporów bez udziału sądu to między innymi
            (1) umożliwienie zbliżenia stanowisk stron, np. poprzez mediację;
            (2) zaproponowanie rozwiązania sporu, np. poprzez koncyliację oraz
            (3) rozstrzygnięcie sporu i narzucenie stronom jego rozwiązania, np.
            w ramach arbitrażu (sąd polubowny). Szczegółowe informacje dotyczące
            możliwości skorzystania przez Klienta będącego konsumentem z
            pozasądowych sposobów rozpatrywania reklamacji i dochodzenia
            roszczeń, zasady dostępu do tych procedur oraz przyjazna
            wyszukiwarka podmiotów zajmujących się polubownym rozwiązywaniem
            sporów dostępne są na stronie internetowej Urzędu Ochrony
            Konkurencji i Konsumentów pod adresem:{" "}
            <a href="https://polubowne.uokik.gov.pl/" className="text-blue-500">
              https://polubowne.uokik.gov.pl/
            </a>
            .
          </p>

          <p className="text-black text-base mt-2 ml-5">
            8.3. Przy Prezesie Urzędu Ochrony Konkurencji i Konsumentów działa
            punkt kontaktowy, którego zadaniem jest między innymi udzielanie
            konsumentom informacji dotyczących pozasądowego rozwiązac pisemnie
            lub osobiście – w Centrali Urzędu przy placu Powstańców Warszawy 1 w
            Warszawie (00-030).
          </p>

          <p className="text-black text-base mt-2 ml-5">
            8.4. Konsument posiada następujące przykładowe możliwości
            skorzystania z pozasądowych sposobów rozpatrywania reklamacji i
            dochodzenia roszczeń: (1) wniosek o rozstrzygnięcie sporu do stałego
            polubownego sądu konsumenckiego; (2) wniosek w sprawie pozasądowego
            rozwiązania sporu do wojewódzkiego inspektora Inspekcji; lub (3)
            pomoc powiatowego (miejskiego) rzecznika konsumentów lub organizacji
            społecznej, do której zadań statutowych należy ochrona konsumentów
            (m. in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).
            Porady udzielane są między innymi mailowo pod adresem{" "}
            <a href="mailto:porady@dlakonsumentow.pl" className="text-blue-500">
              porady@dlakonsumentow.pl
            </a>{" "}
            oraz pod numerem infolinii konsumenckiej 801 440 220 (infolinia
            czynna w Dni Robocze, w godzinach 8:00-18:00, opłata za połączenie
            wg taryfy operatora).
          </p>

          <p className="text-black text-base mt-2 ml-5">
            8.5. Pod adresem{" "}
            <a
              href="http://ec.europa.eu/consumers/odr"
              className="text-blue-500"
            >
              http://ec.europa.eu/consumers/odr
            </a>{" "}
            dostępna jest platforma internetowego systemu rozstrzygania sporów
            pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym
            (platforma ODR). Platforma ODR stanowi interaktywną i wielojęzyczną
            stronę internetową z punktem kompleksowej obsługi dla konsumentów i
            przedsiębiorców dążących do pozasądowego rozstrzygnięcia sporu
            dotyczącego zobowiązań umownych wynikających z internetowej umowy
            sprzedaży lub umowy o świadczenie usług (więcej informacji na
            stronie samej platformy lub pod adresem internetowym Urzędu Ochrony
            Konkurencji i Konsumentów:{" "}
            <a
              href="https://polubowne.uokik.gov.pl/platforma-odr,161,pl.html"
              className="text-blue-500"
            >
              https://polubowne.uokik.gov.pl/platforma-odr,161,pl.html
            </a>
            ).
          </p>

          <p className="text-black font-bold mt-4 text-lg">9. DANE OSOBOWE</p>
          <p className="text-black text-base mt-2 ml-5">
            9.1. Administratorem danych osobowych przetwarzanych w Serwisie
            Internetowym w związku z realizacją postanowień niniejszego
            Regulaminu jest Usługodawca. Dane osobowe przetwarzane są w celach,
            w zakresie i w oparciu o podstawy i zasady wskazane w polityce
            prywatności opublikowanej na stronie Serwisu Internetowego. Polityka
            prywatności zawiera przede wszystkim zasady dotyczące przetwarzania
            danych osobowych przez Administratora w Serwisie Internetowym, w tym
            podstawy, cele i zakres przetwarzania danych osobowych oraz prawa
            osób, których dane dotyczą, a także informacje w zakresie stosowania
            w Serwisie Internetowym plików cookies oraz narzędzi analitycznych.
            Korzystanie ze Serwisu Internetowego jest dobrowolne. Podobnie
            związane z tym podanie danych osobowych przez korzystającego ze
            Serwisu Internetowego jest dobrowolne, z zastrzeżeniem wyjątków
            wskazanych w polityce prywatności.
          </p>
          <p className="text-black font-bold mt-4 text-lg">
            10. PRAWA AUTORSKIE
          </p>
          <p className="text-black text-base mt-2 ml-5">
            10.1. Wszelkie treści i znaki graficzne zamieszczone w Serwisie
            Internetowym chronione są prawami autorskimi przysługującymi
            Usługodawcy zgodnie z ustawą o prawie autorskich i prawach
            pokrewnych z dnia 4 lutego 1994 r. (Dz.U. Nr 24, poz. 83 ze zm.).
          </p>
          <p className="text-black text-base mt-2 ml-5">
            10.2. Usługobiorca może przeglądać materiały publikowane w Serwisie
            Internetowym, w tym w ramach świadczonych Usług Elektronicznych, a
            także pobierać pojedyncze kopie tych materiałów, w granicach
            dozwolonych prawem, wyłącznie do użytku osobistego.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            10.3. Zabronione jest wykorzystywanie treści i znaków graficznych
            zamieszczonych w Serwisie Internetowym w jakikolwiek inny sposób niż
            wskazany w niniejszym Regulaminie lub przez bezwzględnie wiążące
            przepisy prawa.
          </p>

          <p className="text-black font-bold mt-4 text-lg">
            11. POSTANOWIENIA DOTYCZĄCE FIRM
          </p>
          <p className="text-black text-base mt-2 ml-5">
            11.1. Niniejszy punkt Regulaminu oraz wszystkie postanowienia w nim
            zawarte są skierowane i tym samym wiążą wyłącznie Usługobiorcę
            niebędącego konsumentem. Ponadto od dnia 1 stycznia 2021 r. i dla
            umów zawartych od tego dnia niniejszy punkt Regulaminu oraz
            wszystkie postanowienia w nim zawarte nie są skierowane i tym samym
            nie wiążą Usługobiorcy będącego osobą fizyczną zawierającą umowę
            bezpośrednio związaną z jej działalnością gospodarczą, gdy z treści
            tej umowy wynika, że nie posiada ona dla tej osoby charakteru
            zawodowego, wynikającego w szczególności z przedmiotu wykonywanej
            przez nią działalności gospodarczej, udostępnionego na podstawie
            przepisów o Centralnej Ewidencji i Informacji o Działalności
            Gospodarczej, chyba że zastosowanie do tych osób postanowień
            zawartych w tym punkcie Regulaminu nie jest niedozwolone.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            11.2. Usługodawca jest uprawniony w każdym czasie podjąć działania
            zmierzające do zweryfikowania prawdziwości, rzetelności oraz
            precyzyjności informacji podanych przez Usługobiorcę. W zakresie
            weryfikacji Usługodawca uprawniony jest m.in. do zażądania od
            Usługobiorcy przesłania skanu posiadanych certyfikatów, zaświadczeń
            lub innych dokumentów niezbędnych do dokonania weryfikacji. W czasie
            weryfikacji, o której mowa w zdaniu poprzednim Usługodawca
            uprawniony jest do zawieszenia Konta Usługobiorcy na czas trwania
            weryfikacji.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            11.3. Usługodawcy przysługuje prawo odstąpienia od umowy o
            świadczenie Usługi Elektronicznej zawartej z Usługobiorcą w terminie
            14 dni kalendarzowych od dnia jej zawarcia.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            11.4. Usługodawca nie ponosi wobec Usługobiorcy odpowiedzialności za
            szkody i niewypełnienie zobowiązań powstałych na skutek wszelkich
            błędów i awarii technicznych oraz przerw technicznych.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            11.5. Usługodawca nie ponosi wobec Usługobiorcy odpowiedzialności za
            szkody i niewypełnienie zobowiązań wynikające z działania zdarzeń o
            charakterze siły wyższej (np. włamania hackerskie, katastrofy
            naturalne, epidemie, rozruchy, wojny) lub wszelkich innych przyczyn
            pozostających poza kontrolą Usługodawcy.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            11.6. Odpowiedzialność Usługodawcy wobec Usługobiorcy z tytułu
            rękojmi lub braku zgodności z umową zostaje wyłączona.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            11.7. Odpowiedzialność Usługodawcy w stosunku do Usługobiorcy, bez
            względu na jej podstawę prawną, jest ograniczona – zarówno w ramach
            pojedynczego roszczenia, jak również za wszelkie roszczenia w sumie
            – do wysokości uiszczonych Usługodawcy przez takiego Usługobiorcę
            opłat za korzystanie z Usług Elektronicznych, nie więcej jednak niż
            do kwoty jednego tysiąca złotych.
          </p>

          <p className="text-black font-bold mt-4 text-lg">
            12. POSTANOWIENIA KOŃCOWE
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.1. Umowy zawierane poprzez Serwis internetowy zawierane są
            zgodnie z prawem polskim i w języku polskim.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.2. W sprawach nieuregulowanych w niniejszym Regulaminie mają
            zastosowanie przepisy: Kodeksu cywilnego; Ustawy o Prawach
            Konsumenta, Ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą
            elektroniczną (Dz. U. Nr. 144, poz.1204 z późn. zm.) oraz inne
            właściwe przepisy prawa polskiego.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.3. Zmiany w regulaminie:
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.3.1. Usługodawca zastrzega sobie prawo do dokonywania zmian
            Regulaminu z ważnych przyczyn to jest: zmiany sposobów płatności,
            podlegania obowiązkowi prawnemu lub regulacyjnemu; zmiany zakresu
            lub formy świadczonych Usług Elektronicznych; dodania nowych Usług
            Elektronicznych; konieczności przeciwdziałania nieprzewidzianemu i
            bezpośredniemu zagrożeniu związanemu z ochroną Usług Elektronicznych
            i Usługobiorców przed oszustwami, złośliwym oprogramowaniem, spamem,
            naruszeniem danych lub innymi zagrożeniami dla cyberbezpieczeństwa.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.3.2. Powiadomienie o proponowanych zmianach wysyłane jest z
            wyprzedzeniem co najmniej 15 dni przed dniem wejścia w życie tych
            zmian.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.3.3. W przypadku umów o charakterze ciągłym Usługobiorca ma prawo
            rozwiązać umowę z Usługodawcą przed upływem okresu powiadomienia o
            proponowanych zmianach.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.3.4. W wypadku gdyby zmiana Regulaminu skutkowała wprowadzeniem
            jakichkolwiek nowych opłat lub podwyższeniem obecnych konsument ma
            prawo odstąpienia od umowy.
          </p>
          <p className="text-black text-base mt-2 ml-5">
            12.4. Rozstrzyganie sporów: spory powstałe pomiędzy Usługodawcą, a
            Usługobiorcą będącym jednocześnie konsumentem zostają poddane
            właściwym sądom powszechnym. Spory powstałe pomiędzy Usługodawcą, a
            Usługobiorcą niebędącym jednocześnie konsumentem zostają poddane
            sądowi właściwemu ze względu na siedzibę Usługodawcy/Sprzedawcy.
          </p>
        </div>
      </PublicPageLayout>
    </>
  );
};

export default TermsOfService;
